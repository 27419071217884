import { MainChat } from "../Chat/chat"
import { ContentProvider } from "../Chat/providers/ContentProvider"
import Modal from "../Modal"


export const ModalChat = ({ opened, close }) => {
    return (
        <Modal shouldCloseOnOutline={false} isOpen={opened} onClose={close} contentClassName="min-h-[546px] max-h-[546px] relative overflow-hidden w-[320px]">
            <div className="flex flex-col w-[320px] justify-center">
                <div className="font-header font-[500] text-[26px] text-[#242a46] mt-[22px] ml-[24px] text-left  leading-[normal]">
                    Chat
                </div>
                <div className="w-[320px] h-[475px] flex flex-col items-center mt-[18px]">
                    <ContentProvider>
                        <MainChat modalMode={true} />
                    </ContentProvider>
                    {/* <iframe src="https://dev-aid4me.om-api.com/" title="chat" height={517} width={320}  className="remove_scroll overflow-hidden"/> */}
                </div>
            </div>
        </Modal>
    )
}