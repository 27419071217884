import { createContext, useContext, useState, ReactNode } from 'react'

// type MessageType = {
//   text: string
//   id: string
//   sender: 'AI' | 'user'
//   type: 'text' | 'image'
// }

// type ReferenceItemType = {
//   description: string
//   title: string
//   link: {
//     caption: string
//     url: string
//   }
// }

// type ReferencesType = {
//   caption: string
//   items: ReferenceItemType[]
// }

// type PaywallOfferType = {
//   caption: string
//   description: string
// }

// type ContentType = {
//   title: string
//   therapist: string
//   subtitle: string
//   userStartMessage?: string
//   currentFlow: string
//   references: ReferencesType
//   paywall: {
//     title: string
//     subtitle: string
//     button: string
//     offers: PaywallOfferType[]
//   }
//   historyMessages: MessageType[]
// }

// type ContentContextType = {
//   content: ContentType
//   setContent: (content: ContentType) => void
// }

const ContentContext = createContext(undefined)

export const useContent = () => {
  const context = useContext(ContentContext)
  if (!context) {
    throw new Error('useContent must be used within a ContentProvider')
  }
  return context
}

export const ContentProvider = ({ children }) => {
  const [content, setContent] = useState({})

  return (
    <ContentContext.Provider value={{ content, setContent }}>
      {children}
    </ContentContext.Provider>
  )
}
