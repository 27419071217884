
export const SendIcon = (props) => {
  return (
    <svg viewBox="0 0 28 34" fill="none" {...props}>
      <path
        d="M26.576 12.15 15.688 1.263a2.382 2.382 0 0 0-3.372 0L1.426 12.151a2.382 2.382 0 0 0 0 3.371 2.378 2.378 0 0 0 1.685.7c.61 0 1.221-.235 1.686-.7l6.806-6.803v22.6a2.384 2.384 0 1 0 4.77 0V8.69l6.831 6.831c.933.933 2.44.933 3.372 0a2.382 2.382 0 0 0 0-3.371"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
