import { useTranslation } from 'react-i18next';
import { UploadIcon } from '../../../images/main-page-yellow';
import imgLeft from '../imgs/message-left@2x.png';
import imgRight from '../imgs/message-right@2x.png';
import { renderWithLineBreaks } from '../../../utils';

const Message = ({
  text,
  type,
  sender,
  button,
  onLoadingStart,
  onGoToPayment
}) => {
  const messageClass =
    type === 'text'
      ? sender === 'user'
        ? 'bg-[#e1ffc7] border-[#bad5c3] border-[0.5px] ml-auto text-left'
        : 'bg-[#f5f5f5] border-[#C3CEDE] border-[0.5px] text-left'
      : 'ml-auto p-0'
  const { t } = useTranslation();

  return (
    <>
      {button === 'selfie' && (
        <button onClick={onLoadingStart} className="h-[36px] min-h-[36px] w-max px-[20px] flex items-center font-header gap-[8px] justify-center bg-[#000] rounded-[36px] text-[15px] leading-[1.33] text-[#fff] font-[500]">
          <UploadIcon />
          {renderWithLineBreaks(t('CHAT_SELFIE_BUTTON'))}
        </button>)
      }

      {button === 'payment' && (
        <button onClick={onGoToPayment} className="h-[36px] min-h-[36px] w-max px-[20px] flex items-center font-header gap-[8px] justify-center bg-[#000] rounded-[36px] text-[15px] leading-[1.33] text-[#fff] font-[500]">
          {renderWithLineBreaks(t('CHAT_PAYMENT_BUTTON'))}
        </button>)
      }

      {type === 'text' ? <div
        className={`relative w-max max-w-[100%] rounded-2xl border p-[9px_16px_9px] font-text text-[15px] font-normal leading-[1.33] text-[#242a46] tracking-[normal] ${messageClass}`}
      >
        {type === 'text' && <p>{renderWithLineBreaks(text)}</p>}
        {type === 'image' && <img alt="" src={text} className="rounded-2xl" />}
        {type === 'text' && (
          <>
            {sender === 'user' ? (
              <div style={{ backgroundImage: `url(${imgRight})` }} className="absolute bottom-[-0.5px] right-[-5.3px] block h-[17px] w-[27px] bg-cover bg-center bg-no-repeat"></div>
            ) : (
              <div style={{ backgroundImage: `url(${imgLeft})` }} className="absolute bottom-[-0.5px] left-[-5.3px] block h-[17px] w-[27px]  bg-cover bg-center bg-no-repeat"></div>
            )}
          </>
        )}
      </div> : (
        <div className='w-[125px] ml-auto'>
          <img alt="" className='w-[125px] h-[auto] rounded-[16px]' src={text} />
        </div>
      )}

    </>
  )
}

export default Message
