import { FormEvent, useRef, useState } from 'react'
import { ImageUploadIcon } from '../icons/imageUpload'
import classNames from 'classnames'
import { SendIcon } from '../icons/send'

function InputBar({
  onSendMessage
}) {
  const [input, setInput] = useState('')
  const inputRef = useRef(null)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (input.trim()) {
      onSendMessage({ message: input, type: 'text' })
      setInput('')
    }
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const handleImageUpload = (
    event
  )=> {
    return new Promise((resolve, reject) => {
      const file = event.target.files?.[0]
      if (!file) {
        resolve(null)
        return
      }

      const reader = new FileReader()
      reader.onloadend = () => {
        resolve(reader.result)
      }
      reader.onerror = () => {
        reject(new Error('Error reading file'))
      }
      reader.readAsDataURL(file)
    })
  }

  const handleFileChange = async (
    event
  ) => {
    const base64Image = await handleImageUpload(event)
    await onSendMessage({ message: base64Image ?? '', type: 'image' })
  }

  return (
    <form
      className="flex flex-col gap-4 rounded-xl px-0 pb-0 font-SFProDisplay "
      // lg:pb-5 lg:border lg:border-gray-300 lg:px-5 lg:pb-0 lg:pt-[14px]
      onSubmit={handleSubmit}
    >
      {/* <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        className="hidden flex-1  text-[18px] text-[#242a46] lg:block"
        placeholder="Send a message"
        autoFocus={true}
      /> */}
      <div className="flex justify-between rounded-2xl border-[0.5px] border-[#c9c9cb] pt-[8px] pb-[7px] pl-4 pr-[9px] bg-[#fafafa]">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          className="flex-1 text-[#242a46] text-[15px] items-center bg-[#fafafa]"
          placeholder="Enter a message"
          autoFocus={true}
          ref={inputRef}
        />
        <button
          type="submit"
          className="flex size-[26px] items-center rounded-full bg-[#0076ff]"
        >
          <SendIcon className="m-auto h-[14px] text-white" />
        </button>
      </div>
      {/* <div
        className={classNames(
          'justify-center gap-5 lg:justify-between items-center lg:mb-5 hidden lg:flex'
        )}
      >
        <>
          <input
            type="file"
            className="hidden"
            id="chat-file"
            onChange={handleFileChange}
          />
          <label
            htmlFor={'chat-file'}
            className="hidden cursor-pointer rounded-full bg-[#242a46] p-[11px] text-white hover:brightness-[1.02] lg:inline-block lg:rounded lg:bg-[#ececf1] lg:px-3 lg:py-[7px]"
          >
            <ImageUploadIcon className="size-[18px] lg:text-[#353740]" />
          </label>
        </>
        <div className="flex gap-2">
          <button
            type="submit"
            className="order-2 hidden items-center space-x-2 rounded-lg bg-[#10a37f] px-3 py-[6px] font-medium text-white transition-colors hover:brightness-[1.03] lg:flex"
          >
            <p className="m-0 text-sm">Run</p>
            <span className="h-[18px] rounded border border-[#54b598] bg-[#39AD8CA6] px-[5px] text-[12px] font-bold sm:inline">
              ↵
            </span>
          </button>
        </div>
      </div> */}
    </form>
  )
}

export default InputBar
