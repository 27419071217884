import { ScoreBlock } from "../components/NewMainPage/ScoreImage";
import { LogoIcon } from "../images/new-main-page";
import { CameraIcon, MessageIcon, NewLogoIcon, StarsBrownIcon, StarsIcon, UploadIcon, ZoomInIcon } from '../images/main-page-yellow';
import bgHeaderSrc from '../images/main-page-yellow/header-bg.webp';
import scoreSymmetrySrc from '../images/main-page-yellow/score-symmetry.png';
import scoreProportionsSrc from '../images/main-page-yellow/score-proportions.png';
import shareManySrc from '../images/main-page-yellow/double-photo.webp';
import ImageWithBg from '../images/main-page-yellow/img-with-bg.webp';
import imageWithBgOne from '../images/main-page-yellow/img-with-bg-one.webp';
import exampleFace from '../images/main-page-yellow/face-example.webp';
import resultRatingSrc from '../images/main-page-yellow/rating-combined.png';
import bendOvalSrc from '../images/main-page-yellow/bend-oval.png';
import personSrc from '../images/new-main-page/person-photo.png';
import imageCenterSrc from '../images/new-main-page/image-center.webp';
import videoPotentialSrc from '../images/video-potential.gif';
import imageLeftSrc from '../images/new-main-page/image-left.png';
import imageRightSrc from '../images/new-main-page/image-right.png';
import imageBorder from '../images/new-main-page/carousel-border.png';
import neutralTextSrc from '../images/main-page-yellow/Neutral-text.png'
import faceLogoSrc from '../images/main-page-yellow/Face-Logo.png';
import topRatingSrc from '../images/top-rating.png';

import { Bullet } from "../components/NewMainPage/Bullet";
import facebookSrc from '../images/new-main-page/facebook.png';
import youtubeSrc from '../images/new-main-page/youtube.png';
import xSrc from '../images/new-main-page/x.png';
import lndinSrc from '../images/new-main-page/lndin.png';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import { renderWithLineBreaks } from "../utils";
import { GlassesIcon, HairIcon, LogoBlackIcon } from "../icons";
import CircleProgressBar from "../components/ProgressBar";
import { NewScoreScale, RoundScale } from "../components/NewScoreScale";

export function NewMainPage({
    onClickLoadingElem,
    historyBlock,
    onClickHistoryBlock,
    onClickFeedBack,
    showExamples,
    reportExamples,
    fullAnalysis,
    fullAnalysisButton,
    showImages,
    selfieMode,
    onClickAiChat,
    showChatButton,
    step2Data,
    showButtonTips,
}) {
    const { t } = useTranslation();
    const onClickReport = (link) => {
        window.location.href = link
    }

    const renderCard = ({ score, img, title, url, button }) => {
        return (
            <div key={title + score} className="w-[274px] h-[395px] rounded-[20px] bg-[#EDE3DD] overflow-hidden">
                <div className="mt-[16px] px-[12px] h-[36px] flex justify-between w-full">
                    <div className="flex items-center gap-[8px]">
                        <div className="h-[36px] w-[36px] relative flex justify-center items-center">
                            <CircleProgressBar className='absolute left-0 top-0 bottom-0 right-0 z-[1]' smallMode={true} customSize={36} score={score} mainColor={'#F58238'} secondColor={'#FFF'} />
                            <div className="text-[15px] mt-[1.5px] leading-[15px] font-[600] text-[#6E5D50]">
                                {score}
                            </div>
                        </div>
                        <div className="font-satoshi font-[700] text-[15px] text-[#6E5D50]">
                            {title}
                        </div>
                    </div>

                    <button onClick={() => { onClickReport(url) }} className="h-[35px] self-center px-[16px] flex items-center gap-[8px] justify-center bg-[#000] rounded-[48px] text-[15px] font-satoshi text-[#fff] font-[500]">
                        {button}
                    </button>
                </div>
                <img src={img} alt="" className="mt-[12px] w-[274px] h-[331px]" />
            </div>
        )

    }

    return (

        <div className="w-full min-w-[375px] flex flex-col items-center overflow-hidden">

            <div className="w-full  flex flex-col items-center">
                <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/header-bg.webp'})` }} className="bg-[#F07D36] bg-cover bg-center pt-[30px] pb-[90px] w-full flex flex-col">
                    <div className="flex mx-[12px] md:mx-[40px] lg:mx-[60px] justify-between">
                        <LogoBlackIcon />

                        {/* <button className="bg-[#586BFF] px-[16px] py-[10px] rounded-[48px] text-[14px] font-satoshi text-[#fff] font-[500]">
                            Sign In
                        </button> */}

                        {/* {historyBlock.photoCount > 0 ? <div onClick={onClickHistoryBlock} className='min-w-[124px] self-center h-[40px] flex rounded-[48px]  bg-[#586BFF] bg-opacity-15 cursor-pointer pr-[12px]'>
                            <img src={historyBlock.photoSrc} alt="" className='h-[40px] w-[40px] rounded-[32px] ' />
                            <div className='ml-[10px] text-[#140D32] font-satoshi self-center text-[14px] leading-[24px]'>
                                {historyBlock.caption}
                            </div>
                        </div> : <div></div>} */}

                        {/* <button onClick={onClickAiChat} className=" h-[35px] self-center px-[16px] flex items-center gap-[8px] justify-center  rounded-[48px] text-[15px] font-satoshi text-[#000] font-[500]">
                            {renderWithLineBreaks(t('UPLOAD_CHAT_BUTTON'))}
                        </button> */}

                        <button onClick={onClickAiChat} className="h-[44px] self-center px-[16px] flex items-center gap-[6px] justify-center bg-[#000] rounded-[48px] text-[15px] leading-[15px] font-satoshi text-[#fff] font-[500]">
                            <MessageIcon />
                            {renderWithLineBreaks(t('UPLOAD_CHAT_BUTTON'))}
                        </button>


                    </div>
                    <div className="mt-[13px] w-[220px] h-[220px] flex items-center justify-center self-center">
                        <img className="w-[141px] h-[180px]" alt="" src={exampleFace} />
                    </div>

                    <div className="font-satoshi mt-[7px] text-center text-[54px] md:text-[68px] font-[500] text-[#FFF] leading-[54px] md:leading-[68px] tracking-[-2.16px] px-[24px]">
                        {renderWithLineBreaks(t('UPLOAD_MESSAGE_TITLE'))}
                    </div>
                    <div className="mt-[16px] font-satoshi text-center font-[500] text-[21px] text-[#FFF] tracking-[-0.21px] leading-[normal] px-[24px]">
                        {renderWithLineBreaks(t('UPLOAD_MESSAGE_SUBTITLE'))}
                    </div>

                    {false && <div className="h-[381px] mt-[40px] w-full fletopRatingSrcx md:gap-[24px] gap-[12px]  overflow-hidden justify-center items-center">
                        <div className="h-[336px] pb-[16px] rounded-[24px] bg-[rgba(255,255,255,0.82)] relative flex flex-col items-center ml-[-180px]">
                            <img src={imageBorder} alt="" className="w-[167px] h-[165px] absolute top-[38px] left-[25px]" />

                            <img src={process.env.PUBLIC_URL + '/main-image-left-bless.webp'} alt=""
                                width="218px" height="260px"
                                className=" max-h-[260px] rounded-[16px] mt-[-1px]"
                            />
                            <div className="flex  gap-[8px] self-center mt-[24px]">
                                <div className="h-[36px] w-[36px] relative flex justify-center items-center">
                                    <CircleProgressBar className=' absolute left-0 top-0 bottom-0 right-0 z-[1]' smallMode={true} customSize={36} score={86} mainColor={'#F58238'} secondColor={'#6E5D50'} />
                                    <div className="text-[15px] leading-[15px] font-[600] text-[#6E5D50]">
                                        {86}
                                    </div>
                                </div>
                                <div className="text-[#6E5D50] font-satoshi text-[15px] leading-[15px] font-[700] self-center">
                                    {renderWithLineBreaks(t('UPLOAD_CARDS_TITLE'))}
                                </div>
                            </div>

                        </div>


                        <div className="h-[381px] py-[16px] px-[24px] rounded-[24px] bg-[rgba(255,255,255,0.82)] relative flex flex-col items-center">
                            <img src={imageBorder} alt="" className="w-[167px] h-[165px] absolute top-[53px] left-[52px]" />
                            <img src={process.env.PUBLIC_URL + '/image-center-bless.webp'} alt=""
                                width="227px" height="289px"
                                className=" max-h-[289px] rounded-[16px]"
                            />
                            <div className="flex  gap-[8px] self-center mt-[24px]">
                                <div className="h-[36px] w-[36px] relative flex justify-center items-center">
                                    <CircleProgressBar className=' absolute left-0 top-0 bottom-0 right-0 z-[1]' smallMode={true} customSize={36} score={73} mainColor={'#F58238'} secondColor={'#6E5D50'} />
                                    <div className="text-[15px] leading-[15px] font-[600] text-[#6E5D50]">
                                        {73}
                                    </div>
                                </div>
                                <div className="text-[#6E5D50] font-satoshi text-[15px] leading-[15px] font-[700] self-center">
                                    {renderWithLineBreaks(t('UPLOAD_CARDS_TITLE'))}
                                </div>
                            </div>
                        </div>

                        <div className="h-[336px] pb-[16px] rounded-[24px] bg-[rgba(255,255,255,0.82)] relative flex flex-col items-center mr-[-180px]">
                            <img src={imageBorder} alt="" className="w-[167px] h-[165px] absolute top-[38px] left-[25px]" />

                            <img src={process.env.PUBLIC_URL + '/main-image-right-bless.webp'} alt=""
                                width="218px" height="260px"
                                className=" max-h-[260px] rounded-[16px] mt-[-1px]"
                            />
                            <div className="flex  gap-[8px] self-center mt-[24px]">
                                <div className="h-[36px] w-[36px] relative flex justify-center items-center">
                                    <CircleProgressBar className=' absolute left-0 top-0 bottom-0 right-0 z-[1]' smallMode={true} customSize={36} score={74} mainColor={'#F58238'} secondColor={'#6E5D50'} />
                                    <div className="text-[15px] leading-[15px] font-[600] text-[#6E5D50]">
                                        {74}
                                    </div>
                                </div>
                                <div className="text-[#6E5D50] font-satoshi text-[15px] leading-[15px] font-[700] self-center">
                                    {renderWithLineBreaks(t('UPLOAD_CARDS_TITLE'))}
                                </div>
                            </div>

                        </div>
                    </div>}

                    <button onClick={onClickLoadingElem} className=" mt-[81px] h-[48px] self-center w-[188px] flex items-center gap-[8px] justify-center bg-[#000] rounded-[48px] text-[17px] font-satoshi text-[#fff] font-[700]">
                        {renderWithLineBreaks(t('UPLOAD_BUTTON'))}
                    </button>
                    <div className="mt-[22px] flex self-center items-center justify-center gap-[6px]  text-[15px] font-[700] font-satoshi text-[#fff]">
                        <CameraIcon />
                        {renderWithLineBreaks(t('UPLOAD_NOTICE'))}
                    </div>
                </div>


                {/* {showExamples && <div className=" w-full mt-[60px] lg:mt-[120px] bg-[white] flex flex-col">
                    <div className="font-satoshi text-center text-[42px] md:text-[54px] leading-[42px] md:leading-[54px] text-[#000] font-[500] tracking-[-2.16px] px-[24px]">
                        Report examples
                    </div>
                    <div className="text-center mt-[12px] text-[21px] font-[500] text-[#A18875] font-satoshi tracking-[-0.21px] px-[24px]">
                        Look at different reports, that other people created with FaceQ
                    </div>
                    <div className="semi-lg:w-[900px] max-w-full w-full semi-lg:overflow-hidden overflow-y-scroll remove_scroll semi-lg:self-center mt-[60px] ml-[16px] semi-lg:ml-0">
                        <div className=" flex gap-[20px] w-[900px]">
                            {reportExamples.map((ex) => renderCard(ex))}
                        </div>
                    </div>


                </div>} */}

                <div className="bg-[linear-gradient(180deg,#EDE3DD_0%,#FFF_100%)] w-full flex flex-col items-center pt-[60px] ">
                    <div className="font-satoshi text-[15px] font-[700] text-[#6E5D50] tracking-[2.25px] uppercase text-center border-[2px] border-solid border-[#6E5D50] px-[16px] py-[2px] rounded-[40px]">
                        {renderWithLineBreaks(t('UPLOAD_SELFIE_STEP'))}
                    </div>
                    <div className="font-satoshi text-center mt-[12px] px-[24px] text-[42px] font-[500] text-[#140D32] leading-[42px] tracking-[-0.84]">
                        {renderWithLineBreaks(t('UPLOAD_SELFIE_TITLE'))}
                    </div>


                    <div className="bg-[#EAE1DA] mt-[24px] bg-cover w-[290px] rounded-[36px] pb-[24px] flex flex-col items-center">
                        <img loading="lazy" src={imageWithBgOne} className="h-[365px] w-[290px]" alt="" />
                        <button onClick={onClickLoadingElem} className="mt-[24px] h-[36px] self-center w-[156px] flex items-center gap-[8px] justify-center bg-[#000] rounded-[36px] text-[15px] leading-[15px] font-satoshi text-[#fff] font-[500]">
                            <UploadIcon />
                            {renderWithLineBreaks(t('UPLOAD_SELFIE_BUTTON'))}
                        </button>
                    </div>
                    {/* 
                    <img src={process.env.PUBLIC_URL + '/main-image-center.webp'} loading="lazy" className="w-[289px] h-[338px] mt-[32px] rounded-[24px] self-center " alt="" />
                    <button onClick={onClickLoadingElem} className="mt-[32px] h-[48px] self-center w-[188px] flex items-center justify-center bg-[#586BFF] rounded-[48px] text-[14px] font-satoshi text-[#fff] font-[500]">
                        {renderWithLineBreaks(t('UPLOAD_SELFIE_1_BUTTON_IMG'))}
                    </button> */}
                </div>

                {/* <div className="bg-cover bg-[#EAE1DA] w-full max-w-[600px] lg:w-full lg:max-w-full flex flex-col items-center mt-[70px] rounded-[40px] lg:pt-[80px] lg:rounded-none pt-[32px] pb-[34px]">
                    <div className="font-satoshi text-[15px] font-[700] text-[#6E5D50] tracking-[2.25px] uppercase text-center border-[2px] border-solid border-[#6E5D50] px-[16px] py-[2px] rounded-[40px]">
                        {renderWithLineBreaks(t('UPLOAD_SCORE_STEP'))}
                    </div>

                    <div className="font-satoshi text-center text-[42px] font-[500] px-[24px] text-[#000] leading-[42px] tracking-[-0.84px] mt-[12px]">
                        {renderWithLineBreaks(t('UPLOAD_SCORE_TITLE'))}
                    </div>
                    <img src={shareManySrc} loading="lazy" alt="" className="w-[322px] h-[236px] self-center mt-[32px]" />
                    <img src={resultRatingSrc} loading="lazy" alt="" className="w-[250px] h-[140px] self-center" />

                    <div className="mt-[-55px] font-satoshi text-[20px] font-[900] text-[#000] leading-[130%] tracking-[-0.4px] uppercase text-center">
                        {renderWithLineBreaks(t('UPLOAD_SCORE_STATUS_1'))}
                        <br />
                        <span className="text-[#F18B26] font-[900] leading-[130%]">
                            &nbsp;{renderWithLineBreaks(t('UPLOAD_SCORE_STATUS_2'))}
                        </span>
                    </div>

                    <ZoomInIcon className="self-center mt-[44px]" />
                    <div className="mt-[4px] font-satoshi font-[500] text-[#6E5D50] text-[15px] tracking-[-0.15px] leading-[normal] px-[24px] text-center">
                        {renderWithLineBreaks(t('UPLOAD_SCORE_NOTICE'))}
                    </div>
                </div> */}


                <div className="font-satoshi mt-[100px] md:mt-[204px] text-[15px] font-[700] text-[#6E5D50] tracking-[2.25px] uppercase text-center border-[2px] border-solid border-[#6E5D50] px-[16px] py-[2px] rounded-[40px]">
                    {renderWithLineBreaks(step2Data?.step || '')}
                </div>

                <div className="font-satoshi mt-[12px] text-center text-[42px]  font-[500] px-[24px] text-[#000] leading-[42px] md:leading-[60px] md:text-[60px] tracking-[-0.84px]">
                    {/* {renderWithLineBreaks(t('UPLOAD_ANALYSIS_TITLE'))} */}
                    {renderWithLineBreaks(step2Data?.scoreTitle || '')}
                </div>

                {/* 
                <NewScoreScale score={score} /> */}

                <div className="mx-[24px] mt-[32px] md:mt-[60px] self-stretch sm:self-center md:w-[668px] lg:w-[766px]">

                    <div className="w-full mt-[12px] bg-cover h-[216px] rounded-[24px] flex flex-col items-center bg-[#EAE1DA]" >
                        <NewScoreScale className="mt-[30px] md:w-[300px] md:h-[156px] w-[250px] h-[140px]" score={step2Data?.score?.score} />

                        <div className="md:mt-[-70px] mt-[-60px] font-satoshi md:text-[24px] text-[20px] font-[900] text-[#000] leading-[130%] tracking-[-0.4px] uppercase text-center">
                            {step2Data?.score?.text.base}
                            <br />
                            <span className="text-[#F18B26] font-[900] leading-[130%]">
                                &nbsp;{step2Data?.score?.text.result}
                            </span>
                        </div>

                    </div>

                    <div className="flex w-full gap-[12px] mt-[12px]"  >
                        <div className="h-[195px] md:h-[288px] w-full rounded-[24px] bg-cover flex flex-col md:pt-[52px] pt-[20px] items-center bg-[#EAE1DA]" >
                            {/* <img alt="" className="w-[145px] h-[145px]" src={scoreSymmetrySrc} /> */}
                            <RoundScale score={step2Data?.score?.firstBlock.score || 0} />
                            <div className="mt-[20px] md:mt-[31px] uppercase text-[13px] md:text-[16px] font-satoshi font-[500] max-w-[105px] md:max-w-[145px] text-center  leading-[1] text-[#000] opacity-40 tracking-[1.35px]">
                                {renderWithLineBreaks(step2Data?.score?.firstBlock.caption || '')}
                            </div>
                        </div>

                        <div className="h-[195px] md:h-[288px] w-full rounded-[24px] bg-cover flex flex-col md:pt-[52px] pt-[20px] items-center bg-[#EAE1DA]" >
                            <RoundScale score={step2Data?.score?.secondBlock.score || 0} />
                            <div className="mt-[20px] md:mt-[31px] uppercase text-[13px] md:text-[16px] font-satoshi font-[500] max-w-[105px] md:max-w-[145px] text-center leading-[1] text-[#000] opacity-40 tracking-[1.35px]">
                                {renderWithLineBreaks(step2Data?.score?.secondBlock.caption || '')}
                            </div>
                        </div>
                    </div>

                    <div className="w-full p-[24px] rounded-[24px] bg-[#EAE1DA] flex flex-col md:flex-row md:justify-between mt-[24px] md:mt-[12px]">
                        <div className="flex flex-col">
                            <div className="font-satoshi text-[13px] font-[500] text-[#000] opacity-40 uppercase leading-[1] md:text-left text-center">
                                {renderWithLineBreaks(step2Data?.top?.subtitle)}
                            </div>
                            <div className="mt-[12px] text-[42px] font-[500] text-[#000] font-satoshi tracking-[-0.84px] leading-[1] md:text-left text-center">
                                {renderWithLineBreaks(step2Data?.top?.title)}
                            </div>
                        </div>

                        <img alt="" src={topRatingSrc} className="mt-[32px] md:mt-0 w-[280px] h-[50px] self-center" />

                    </div>

                    <div className="font-satoshi mt-[80px] md:mt-[112px] text-center text-[42px] font-[500] px-[24px] text-[#000] leading-[42px] md:leading-[60px] md:text-[60px] tracking-[-0.84px]">
                        {/* {renderWithLineBreaks(t('UPLOAD_ANALYSIS_TITLE'))} */}
                        {renderWithLineBreaks(step2Data?.analysisTitle || '')}
                    </div>

                    <div className="flex w-full gap-[12px] mt-[32px] md:mt-[60px]"  >
                        <div className="h-[195px] md:h-[288px] w-full rounded-[24px] md:pt-[52px] pt-[20px] bg-cover flex flex-col items-center bg-[#EAE1DA]" >
                            <RoundScale score={step2Data?.full?.firstBlock.score || 0} />
                            <div className="mt-[20px] md:mt-[31px] uppercase text-[13px] md:text-[16px] font-satoshi font-[500] max-w-[105px] md:max-w-[145px] text-center  leading-[1] text-[#000] opacity-40 tracking-[1.35px]">
                                {renderWithLineBreaks(step2Data?.full?.firstBlock.caption || '')}
                            </div>
                        </div>
                        <div className="h-[195px] md:h-[288px] w-full rounded-[24px] md:pt-[52px] pt-[20px] bg-cover flex flex-col items-center bg-[#EAE1DA]" >
                            <RoundScale score={step2Data?.full?.secondBlock.score || 0} />
                            <div className="mt-[20px] md:mt-[31px] uppercase text-[13px] md:text-[16px] font-satoshi font-[500] max-w-[105px]  md:max-w-[145px] text-center   leading-[1] text-[#000] opacity-40 tracking-[1.35px]">
                                {renderWithLineBreaks(step2Data?.full?.secondBlock.caption || '')}
                            </div>
                        </div>

                        {/* <img className="w-[136px] h-[68px] mt-[20px]" alt="" src={bendOvalSrc} />
                        <div className="uppercase text-[13px] mt-[20px] font-satoshi font-[500] text-[#000] opacity-40">
                            {renderWithLineBreaks(t('UPLOAD_ANALYSIS_3_VALUE'))}
                        </div> */}
                    </div>


                    {step2Data?.full && (step2Data.full.textEvals.map(({ caption, score }) => (
                        <div key={caption} className="w-full mt-[12px] bg-cover rounded-[24px] flex justify-between items-center px-[16px] h-[53px] bg-[#EAE1DA]" >
                            <div className="uppercase text-[13px] leading-[13px] font-satoshi font-[500] text-[#000] opacity-40">
                                {renderWithLineBreaks(caption)}
                            </div>
                            <div className=" uppercase text-[17px] font-satoshi font-[600] text-[#121211] tracking-[-0.34px]">
                                {renderWithLineBreaks(score)}
                            </div>
                        </div>
                    )))}

                </div>

                <div className="font-satoshi mt-[112px] text-center text-[42px] md:text-[60px] font-[500] px-[24px] text-[#000] leading-[42px] md:leading-[60px] tracking-[-0.84px]">
                    {/* {renderWithLineBreaks(t('UPLOAD_ANALYSIS_TITLE'))} */}
                    {renderWithLineBreaks(step2Data?.insightsTitle || '')}
                </div>


                <div className="mx-[24px] py-[24px] mt-[32px] md:mt-[60px] flex flex-col self-center md:w-[668px] lg:w-[766px] w-[327px] rounded-[24px]  px-[16px] bg-[#EAE1DA]">
                    <div className="w-full uppercase text-[13px] font-satoshi text-center font-[500] text-[#000] opacity-40">
                        {renderWithLineBreaks(step2Data?.insights?.similar.title || '')}
                    </div>

                    <div className="mt-[24px] hidden md:flex gap-[4px] rounded-[16px] overflow-hidden self-center">
                        {
                            step2Data?.insights?.similar.imgs.map(({ img_b, caption }) => (
                                <div key={img_b} className="">
                                    <img alt="" src={img_b} className="w-[160px] h-[120px] min-w-[160px] rounded-[4px]" />
                                    <div className="mt-[8px] text-[13px] font-satoshi text-center leading-[1] font-[500] text-[#000] opacity-40">
                                        {renderWithLineBreaks(caption)}
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    <div className="mt-[24px] flex md:hidden gap-[4px] rounded-[16px] overflow-hidden self-center flex-wrap">
                        {
                            step2Data?.insights?.similar.imgs.map(({ img_s, caption }) => (
                                <div key={img_s} className="">
                                    <img alt="" src={img_s} className="w-[145px] min-w-[145px] h-[120px] rounded-[4px]" />
                                    <div className="mt-[8px] text-[13px] font-satoshi text-center font-[500] leading-[1] text-[#000] opacity-40">
                                        {renderWithLineBreaks(caption)}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className="mx-[20px] mt-[20px] flex gap-[20px] flex-col md:flex-row  self-center md:w-[668px] lg:w-[766px] w-[327px] ">
                    <div className="flex flex-col px-[24px] py-[40px] bg-[#EAE1DA] rounded-[24px] grow shrink basis-0 min-w-[327px]">
                        <div className="w-full uppercase text-[13px] font-satoshi text-center font-[500] text-[#000] opacity-40 ">
                            {renderWithLineBreaks(step2Data?.insights?.haircut.title || '')}
                        </div>
                        <HairIcon className="mt-[20px] self-center" />
                        <div className="flex gap-[6px] w-full mt-[20px] justify-center ">
                            <img alt="" className="h-[180px] inline-block md:hidden rounded-[20px] border-[5px] border-solid border-[#F5F5F5]" src={step2Data?.insights?.haircut.imgs[0].img_s} />
                            <img alt="" className="h-[180px] md:inline-block hidden rounded-[20px] border-[5px] border-solid border-[#F5F5F5]" src={step2Data?.insights?.haircut.imgs[0].img_b} />

                            <img alt="" className="h-[180px] inline-block md:hidden rounded-[20px] border-[5px] border-solid border-[#F5F5F5]" src={step2Data?.insights?.haircut.imgs[1].img_s} />
                            <img alt="" className="h-[180px] md:inline-block hidden rounded-[20px] border-[5px] border-solid border-[#F5F5F5]" src={step2Data?.insights?.haircut.imgs[1].img_b} />
                        </div>
                        <div className="font-satoshi mt-[12px] font-[500] text-[#000] text-[15px] leading-[normal] opacity-40 text-left">
                            {renderWithLineBreaks(step2Data?.insights?.haircut.description || '')}
                        </div>
                    </div>

                    <div className="flex flex-col px-[24px] py-[40px] bg-[#EAE1DA] rounded-[24px] grow shrink basis-0 min-w-[327px]">
                        <div className="w-full uppercase text-[13px] font-satoshi text-center font-[500] text-[#000] opacity-40">
                            {renderWithLineBreaks(step2Data?.insights?.glasses.title || '')}
                        </div>
                        <GlassesIcon className="mt-[20px] self-center" />
                        <div className="flex gap-[6px] w-full mt-[20px] justify-center">
                            <img alt="" className="h-[180px] inline-block md:hidden rounded-[20px] border-[5px] border-solid border-[#F5F5F5]" src={step2Data?.insights?.glasses.imgs[0].img_s} />
                            <img alt="" className="h-[180px] md:inline-block hidden rounded-[20px] border-[5px] border-solid border-[#F5F5F5]" src={step2Data?.insights?.glasses.imgs[0].img_b} />

                            <img alt="" className="h-[180px] inline-block md:hidden rounded-[20px] border-[5px] border-solid border-[#F5F5F5]" src={step2Data?.insights?.glasses.imgs[1].img_s} />
                            <img alt="" className="h-[180px] md:inline-block hidden rounded-[20px] border-[5px] border-solid border-[#F5F5F5]" src={step2Data?.insights?.glasses.imgs[1].img_b} />
                        </div>
                        <div className="font-satoshi mt-[12px] font-[500] text-[#000] text-[15px] leading-[normal] opacity-40 text-left">
                            {renderWithLineBreaks(step2Data?.insights?.glasses.description || '')}
                        </div>
                    </div>

                </div>


                <div className="mx-[24px] mt-[32px]  self-stretch sm:self-center md:w-[600px] lg:w-[600px] sm:overflow-y-hidden overflow-y-scroll remove_scroll">
                    <div className="flex md:w-full md:min-w-[unset] gap-[8px] min-w-[600px] w-[600px]">
                        {
                            fullAnalysis.slice(0, 2).map(({ score, title, imgs, tags, avatar, description, notice }, ind) => (
                                <div key={ind} className="h-[648px] w-[295px] min-w-[273px] rounded-[24px] py-[20px] flex flex-col items-center bg-[#EAE1DA] overflow-hidden">
                                    <div className=" font-satoshi font-[500] text-[13px] tracking-[1.04px] uppercase text-center text-[#000] opacity-40 leading-[100%]">
                                        {title}
                                    </div>
                                    <img loading="lazy" alt="" className="mt-[20px] w-[165px] h-[204px] rounded-[30px] border-[5px] border-solid border-[#F5F5F5]" src={avatar} />
                                    <div className="mt-[12px] px-[24px] text-[15px] font-satoshi font-[500] text-[#000] opacity-40 text-center leading-[133%] tracking-[-0.15]">
                                        {renderWithLineBreaks(description)}
                                    </div>
                                    <div className="mt-[28px] flex gap-[4px] w-[246px] h-[246px] overflow-hidden flex-wrap rounded-[16px]">
                                        {
                                            imgs.map((img, ind) => (
                                                <img className="w-[121px] h-[121px]" alt="" src={img} key={ind} />
                                            ))
                                        }
                                    </div>
                                    <div className="mt-[12px] text-[13px] font-[500] leading-[100%] text-center opacity-40">
                                        {notice}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>

                {showButtonTips && <a href={fullAnalysisButton.url} className="mt-[20px] h-[36px] self-center px-[20px] flex items-center gap-[8px] justify-center bg-[#000] rounded-[48px] text-[15px] leading-[15px] font-satoshi text-[#fff] font-[500]">
                    {fullAnalysisButton.button}
                </a>}

                <div className="bg-[#EAE1DA] bg-cover w-full max-w-[600px] lg:max-w-[924px] flex flex-col items-center mt-[60px] rounded-[40px]  pt-[32px] pb-[16px]">
                    <div className="font-satoshi text-[15px] font-[700] text-[#6E5D50] tracking-[2.25px] uppercase text-center border-[2px] border-solid border-[#6E5D50] px-[16px] py-[2px] rounded-[40px]">
                        {renderWithLineBreaks(t('UPLOAD_IMAGE_STEP'))}
                    </div>

                    <div className="font-satoshi text-center text-[42px] px-[24px] font-[500] text-[#000] leading-[42px] tracking-[-0.84px] mt-[12px]">
                        {renderWithLineBreaks(t('UPLOAD_IMAGE_TITLE'))}
                    </div>

                    <img loading="lazy" alt="" className="w-[255px] h-[278px] mt-[32px] rounded-[36px]" src={videoPotentialSrc} />

                    {showChatButton && <button onClick={onClickAiChat} className="mt-[32px] h-[36px] self-center px-[20px] flex items-center gap-[8px] justify-center bg-[#000] rounded-[48px] text-[15px] leading-[15px] font-satoshi text-[#fff] font-[500]">
                        {renderWithLineBreaks(t('UPLOAD_IMAGE_CHAT_BUTTON'))}
                    </button>}

                    <StarsBrownIcon className="self-center mt-[44px]" />
                    <div className="mt-[4px] font-satoshi font-[500] text-[#6E5D50] text-[15px] tracking-[-0.15px] leading-[normal] px-[40px] text-center">
                        {renderWithLineBreaks(t('UPLOAD_IMAGE_NOTICE'))}
                    </div>
                </div>

                {showExamples && <div className=" w-full mt-[60px] lg:mt-[60px] bg-[white] flex flex-col">
                    <div className="font-satoshi text-center text-[42px] md:text-[54px] leading-[42px] md:leading-[54px] text-[#000] font-[500] tracking-[-2.16px] px-[24px]">
                        {renderWithLineBreaks(t('UPLOAD_EXAMPLE_TITLE'))}
                    </div>
                    <div className="text-center mt-[12px] text-[21px] font-[500] text-[#A18875] font-satoshi tracking-[-0.21px] px-[24px]">
                        {renderWithLineBreaks(t('UPLOAD_EXAMPLE_SUBTITLE'))}
                    </div>
                    <div className="semi-lg:w-[900px] max-w-full w-full semi-lg:overflow-hidden overflow-y-scroll remove_scroll semi-lg:self-center mt-[60px] ml-[16px] semi-lg:ml-0">
                        <div className="flex gap-[20px] w-[900px]">
                            {reportExamples.map((ex) => renderCard(ex))}
                        </div>
                    </div>

                    <button onClick={onClickLoadingElem} className="mt-[64px] h-[50px] self-center w-[216px] flex items-center gap-[8px] justify-center bg-[#000] rounded-[36px] text-[15px] leading-[15px] font-satoshi text-[#fff] font-[500]">
                        {renderWithLineBreaks(t('UPLOAD_EXAMPLE_BUTTON'))}
                    </button>

                    <StarsBrownIcon className="self-center mt-[44px]" />
                    <div className="mt-[4px] font-satoshi font-[500] text-[#6E5D50] text-[15px] tracking-[-0.15px] leading-[normal] px-[40px] text-center">
                        {renderWithLineBreaks(t('UPLOAD_EXAMPLE_NOTICE'))}
                    </div>
                </div>}


                <img loading="lazy" src={faceLogoSrc} className="mt-[60px] w-[102px] h-[40px]" alt="" />

                <footer className="w-full flex flex-col  items-center pt-[40px] pb-[32px] ">
                    {/* <div className="w-[186px] flex justify-between items-center">
                        <img loading="lazy" src={facebookSrc} className="w-[24px] h-[24px]" alt="" />
                        <img loading="lazy" src={youtubeSrc} className="w-[31px] h-[21px]" alt="" />
                        <img loading="lazy" src={xSrc} className="w-[24px] h-[22px]" alt="" />
                        <img loading="lazy" src={lndinSrc} className="w-[23px] h-[24px]" alt="" />
                    </div> */}
                    <div className="font-satoshi text-[15px] font-[500] tracking-[-0.15px] text-[#BBB]">
                        {/* {renderWithLineBreaks(t('COPYRIGHT'))} */}
                        {renderWithLineBreaks(t('COPYRIGHT'))}
                    </div>

                    <div className="mt-[40px]  tracking-[-0.15px] font-satoshi text-[15px] font-[500] text-[#BBB] gap-[24px] flex">
                        <Link className="underline" to="/pri">{renderWithLineBreaks(t('UPLOAD_PRIVACY'))}</Link >
                        <Link className="underline" to="/ter">{renderWithLineBreaks(t('UPLOAD_TERMS'))}</Link>
                    </div>
                </footer>
            </div>
        </div >
    )
}