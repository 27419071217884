import { getColorByType, getTypeByScore, renderWithLineBreaks } from "../../utils";
import { ChartLoadingIcon, ReloadBlueIcon, ReloadWhiteIcon, StarsBlueIcon } from "../../icons";
import { SpeedometerCombined } from "../Speedometer";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import { useShowExpressScore } from "../../context";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import starSrc from '../../images/iconed-png/star.png';
import crownSrc from '../../images/iconed-png/crown.png';
import starsSrc from '../../images/iconed-png/stars.png';
import chainSrc from '../../images/iconed-png/chain.png';
import { makeRequest } from "../../utils/request";

export const SharePaywall = ({
    onClickContinue,
    scoreId,
    goToResult,
}) => {
    const { t } = useTranslation();
    const [copied, setCopied] = useState(false);
    const [inviteMode, setInviteMode] = useState(true);
    const [inviteStatusSmallText, setInviteStatusSmallText] = useState(t('INVITE_STEP3_STATUS'))
    const [inviteStatusText, setInviteStatusText] = useState(t('INVITE_NOTICE_LINE_2'))


    const [reloading, setReloading] = useState(false);
    const pay = useCallback(() => {
        window.dataLayer.push({ 'event': 'unlock' });
        onClickContinue('unlock');
    }, [onClickContinue])

    const invite = () => {
        setInviteMode(true);
    }

    const link = useMemo(() => {
        const origin = window.location.origin;
        return `${origin}/invite/${scoreId}`
    }, [scoreId])

    const copyLink = () => {
        if (navigator?.clipboard?.writeText) {
            setCopied(true)
            navigator.clipboard.writeText(link);
            setTimeout(() => { setCopied(false) }, 2000)
        }
    }

    const onReload = async () => {
        setReloading(true);
        const result = await makeRequest('/invite/status', 'post', {
            score_id: scoreId,
        });
        setReloading(false);
        if (result.data.result.status === 0) {
            setInviteStatusSmallText(result.data.result.text_s);
            setInviteStatusText(result.data.result.text);
        }
        if (result.data.result.status === 1) {
            goToResult(scoreId)
        }
    }

    return (
        <>


            {inviteMode ? (
                <>
                    <div className="text-[26px] font-[600] text-[#232948] leading-[normal] text-center">
                        {renderWithLineBreaks(t('INVITE_TITLE'))}
                    </div>

                    <div className="mt-[20px] w-full bg-[#f6f7f8] rounded-[28px] py-[18px] flex flex-col items-center">
                        <div className="text-[18px] leading-[24px] font-bold font-header text-[#232948]">
                            {renderWithLineBreaks(t('INVITE_STEP1_CAPTION'))}

                        </div>

                        <div className="text-[14px] leading-[24px] font-header text-[#878787]">
                            {link}
                        </div>
                        <div className="relative">
                            <button onClick={copyLink} className="mt-[12px] rounded-[22px] border-[3px] border-solid border-[#0076ff] text-[#0076ff] px-[41px] py-[6px] text-[17px] leading-[20px] font-header font-bold">
                                {renderWithLineBreaks(t('INVITE_STEP1_BUTTON'))}
                            </button>

                            {copied && (
                                <div className="absolute top-[-20px] left-1/2 transform -translate-x-1/2 bg-black text-white text-[12px] rounded-[4px] px-[8px] py-[4px]">
                                    Copied!
                                </div>)}
                        </div>

                    </div>

                    <div className="mt-[10px] w-full bg-[#f6f7f8] rounded-[28px] py-[18px] flex flex-col items-center px-[44px]">
                        <div className="text-[18px] leading-[24px] font-bold font-header text-[#232948]">
                            {renderWithLineBreaks(t('INVITE_STEP2_CAPTION'))}
                        </div>

                    </div>

                    <div className="mt-[10px] w-full bg-[#f6f7f8] rounded-[28px] py-[22px] flex flex-col items-center">
                        <div className="text-[18px] leading-[24px] font-bold font-header text-[#232948] text-center px-[40px]">
                            {renderWithLineBreaks(t('INVITE_STEP3_CAPTION'))}
                        </div>

                        <div className="text-[14px] leading-[24px] font-header text-[#878787] text-center">
                            {renderWithLineBreaks(inviteStatusSmallText)}
                        </div>

                        <button onClick={onReload} className="mt-[12px] rounded-[22px] border-[3px] border-solid border-[#0076ff] text-[#0076ff] px-[38px] py-[6px] text-[17px] leading-[20px] font-header font-bold flex gap-[8px]">
                            <ReloadBlueIcon className={`${reloading ? 'loader' : ''} h-[20px]`} />
                            <span className={`${reloading ? 'opacity-0' : 'self-center'}`}>
                                {renderWithLineBreaks(t('INVITE_RELOAD'))}
                            </span>
                        </button>
                    </div>



                    <div className="mt-[13px] text-center text-[14px] opacity-80 text-[#010101] leading-[1.29]">
                        {renderWithLineBreaks(inviteStatusText)}
                    </div>

                </>
            ) : <>
                <div className="text-[26px] font-[600] text-[#232948] leading-[normal] text-center">
                    {renderWithLineBreaks(t('ACCESS_TITLE'))}
                </div>

                <div className="mt-[41px] flex">
                    <button onClick={pay} className='font-bold w-[240px] h-[64px] flex text-xl text-[white] justify-center items-center bg-[#0076ff] rounded-[16px]'>
                        {renderWithLineBreaks(t('ACCESS_PAY_BUTTON'))}
                    </button>
                </div>
                <div className="mt-[10px] text-center text-[14px] opacity-80 text-[#010101] leading-[1.29]">
                    {renderWithLineBreaks(t('ACCESS_PAY_NOTICE'))}
                </div>

                <div className="mt-[35px] relative border-t-[1px] border-solid border-[#232948] w-full">
                    <div className="w-[83px] bg-white h-[23px] flex items-center justify-center text-[19px] font-header text-[#232948] left-[75px] top-[-13px] absolute">
                        {renderWithLineBreaks(t('ACCESS_OR'))}
                    </div>
                </div>

                <div className="mt-[31px] flex">
                    <button onClick={invite} className='font-bold w-[240px] h-[64px] flex text-xl text-[#000] justify-center items-center bg-[#f4f5f8] rounded-[16px]'>
                        {renderWithLineBreaks(t('ACCESS_INVITE_BUTTON'))}
                    </button>
                </div>

                <div className="mt-[10px] text-center text-[14px] opacity-80 text-[#010101] leading-[1.29]">
                    {renderWithLineBreaks(t('ACCESS_INVITE_NOTICE'))}
                </div>
            </>}


            {/* <div className=' mt-[21px]  w-[241px] flex flex-col gap-[8px]'>
                <div className="flex w-full items-center gap-[8px] h-[61px] px-[8px] bg-[#811883] rounded-[16px]">
                    <div className="w-[32px] min-w-[32px] flex items-center justify-center">
                        <img className="w-[auto] h-[24px]" src={starSrc} alt="" />
                    </div>
                    <div className="text-[#f2f2f2] text-[14px] leading-[1.29] font-[500]">
                        {renderWithLineBreaks(t('PAYWALL_VALUE_RATINGS'))}
                    </div>
                </div>
                <div className="flex w-full items-center gap-[8px] h-[61px] px-[8px] bg-[#811883] rounded-[16px]">
                    <div className="w-[32px] min-w-[32px] flex items-center justify-center">
                        <img className="w-[auto] h-[22px]" src={crownSrc} alt="" />
                    </div>

                    <div className="text-[#f2f2f2] text-[14px] leading-[1.29] font-[500]">
                        {renderWithLineBreaks(t('PAYWALL_VALUE_FEATURES'))}
                    </div>
                </div>
                <div className="flex w-full items-center gap-[8px] h-[61px] px-[8px] bg-[#811883] rounded-[16px]">
                    <div className="w-[32px] min-w-[32px] flex items-center justify-center">
                        <img className="w-[auto] h-[22px]" src={starsSrc} alt="" />
                    </div>

                    <div className="text-[#f2f2f2] text-[14px] leading-[1.29] font-[500]">
                        {renderWithLineBreaks(t('PAYWALL_VALUE_SELFIES'))}
                    </div>
                </div>

                <div className="flex w-full items-center gap-[8px] h-[61px] px-[8px] bg-[#811883] rounded-[16px]">
                    <div className="w-[32px] min-w-[32px] flex items-center justify-center">
                        <img className="w-[auto] h-[24px]" src={chainSrc} alt="" />
                    </div>

                    <div className="text-[#f2f2f2] text-[14px] leading-[1.29] font-[500]">
                        {renderWithLineBreaks(t('PAYWALL_VALUE_URL'))}
                    </div>
                </div>
            </div>
            <div className="mt-[10px] flex">
                <button onClick={pay} className='font-bold w-[240px] h-[64px] flex text-xl text-[white] justify-center items-center bg-[#0076ff] rounded-[16px]'>
                    {renderWithLineBreaks(t('PAYWALL_BUTTON'))}
                </button>
            </div>
            <div className="mt-[13px] text-center text-[14px] opacity-80 text-[#010101] leading-[1.29]">

                {renderWithLineBreaks(t('PAYWALL_NOTICE'))}
            </div> */}
        </>
    )
}