import axios from "axios";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from 'uuid';

export const makeRequest = async (url, method = 'post', body, options, useUdid = true) => {
    let cookie = getUdid();

    if (useUdid) {
        if (body instanceof FormData) {
            body.append('udid', cookie)
        } else {
            body = {
                ...body,
                udid: cookie
            }
        }
    }


    if (method === 'post') {
        return await axios.post(`${process.env.REACT_APP_API_LINK}${url}`, body, options)
    }
    if (method === 'get') {
        return await axios.get(`${process.env.REACT_APP_API_LINK}${url}`, options)
    }

    throw new Error('method is not supported')
}

export const getUdid = () => {
    let udid =  Cookies.get('udid') || '';

    if (!udid) {
        const newUdid = uuidv4();
        Cookies.set('udid', newUdid, { expires: 390 })
        udid = newUdid;
    }
    return udid;
}