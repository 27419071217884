import Message from './Message'
import './message.css'
import imgLeft from '../imgs/message-left@2x.png';
import { useTranslation } from 'react-i18next';
import { getPaymentRequest, renderWithLineBreaks } from '../../../utils';
import { UploadIcon } from '../../../images/main-page-yellow';
import { useOnStartLoadPhoto, usePaymentInformation } from '../../../context/PhotoLoadingContext';
import usePhotoLoader from '../../../hooks/usePhotoLoader';
import { useCallback } from 'react';
import { useCloseChatModal } from '../../../context';
import { getUdid } from '../../../utils/request';
import { useHistory } from 'react-router-dom';

function MessageList({
  messages,
  isLoading,
}) {
  const onStartLoadPhoto = useOnStartLoadPhoto();
  const closeChatModal = useCloseChatModal();
  const history = useHistory();
  const loadingCallback = (...args) => {
    closeChatModal();
    onStartLoadPhoto(...args)
  }

  const { openFileSelector } = usePhotoLoader(loadingCallback)

  const onLoadingStart = useCallback(() => {
    openFileSelector('none');
  }, [openFileSelector])

  const paymentInformation = usePaymentInformation();

  const onGoToPayment = useCallback(async () => {
    if (!paymentInformation) {
      return;
    }
    const { paymentItem, paymentProject, scoreId } = paymentInformation
    const res = await getPaymentRequest(getUdid(), paymentItem, paymentProject, scoreId)
    if (res.data.redirect_url) {
      window.location.href = res.data.redirect_url;
    } else {
      history.push('/err')
    }
  }, [paymentInformation, history])

  return (
    <div className="relative flex flex-1 flex-col overflow-hidden">
      <div className="absolute bottom-0 flex max-h-full w-full flex-1 flex-col-reverse gap-2 overflow-auto p-[10px_16px] scrollbar-hide">

        {isLoading && (
          <div className="relative flex w-[64px] gap-1 rounded-2xl border-[0.5px] border-[#C3CEDE]/50 bg-[#f5f5f5] p-[18px] text-left leading-[1.27] text-black">
            <div className="dot dot1"></div>
            <div className="dot dot2"></div>
            <div className="dot dot3"></div>
            <div className="dot dot4"></div>
            <div style={{ backgroundImage: `url(${imgLeft})` }} className="absolute bottom-[-0.5px] left-[-5.6px] block h-[17px] w-[27px] bg-cover bg-center bg-no-repeat"></div>
          </div>
        )}
        {messages.map((message, index) => (
          <Message key={index} {...message} isLoading={isLoading} onLoadingStart={onLoadingStart} onGoToPayment={onGoToPayment} />
        ))}

      </div>
    </div>
  )
}

export default MessageList
