import { useEffect, useRef, useState } from 'react'

const useAudioPlayer = () => {
  const audioContextRef = useRef(null)
  const [isInitialized, setIsInitialized] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)

  const analyserRef = useRef(null)
  const [volume, setVolume] = useState(0)

  const init = async () => {
    try {
      audioContextRef.current = new AudioContext({ sampleRate: 24000 })

      setIsInitialized(true)
    } catch (e) {
      console.error('Error initializing audio context or worklet:', e)
    }
  }

  useEffect(() => {
    init()
    return () => {
      if (audioContextRef.current?.state === 'running') {
        audioContextRef.current?.close()
      }
    }
  }, [])

  useEffect(() => {
    if (isPlaying && analyserRef.current) {
      const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount)
      const updateVolume = () => {
        analyserRef.current?.getByteFrequencyData(dataArray)
        const avgVolume =
          dataArray.reduce((sum, value) => sum + value, 0) / dataArray.length
        setVolume(avgVolume)
        requestAnimationFrame(updateVolume)
      }
      requestAnimationFrame(updateVolume)
    }
  }, [isPlaying])

  const play = (audioData) => {
    init()

    if (audioContextRef.current) {
      audioContextRef.current.decodeAudioData(
        audioData,
        (buffer) => {
          const source = audioContextRef?.current?.createBufferSource()
          if (source && audioContextRef?.current?.destination) {
            source.buffer = buffer
            source?.connect(audioContextRef?.current?.destination)
            source?.start(0)
          }
        },
        (error) => {
          console.error('Error during Audio decoding', error)
        }
      )
    }

    setIsPlaying(true)
  }

  const stop = () => {
    if (audioContextRef.current?.state === 'running') {
      audioContextRef.current?.suspend()
    }

    setIsPlaying(false)
  }

  const reset = () => {
    if (
      audioContextRef.current?.state === 'suspended' ||
      audioContextRef.current?.state === 'running'
    ) {
      audioContextRef.current?.close()
      setIsPlaying(false)
    }
  }

  return {
    play,
    stop,
    reset,
    isInitialized,
    isPlaying,
    init,
    outputVolume: volume
  }
}

export default useAudioPlayer
