import { useEffect, useState } from 'react'
import MessageList from './MessageList'
import InputBar from './InputBar'
// import { getVoiceMessage, sendMessage } from '../../api'
import { sendMessage, getVoiceMessage } from '../api'
import useAudioPlayer from '../hooks/useAudioPlayer'
import { useSound } from '../providers/SoundProvider'
import { v4 as uuidv4 } from 'uuid'
import { useContent } from '../providers/ContentProvider'
import { useChatMode } from '../../../context'
import { usePaymentInformation } from '../../../context/PhotoLoadingContext'
// import { PaywallModal } from './PaywallModal'

const PAYWALL_FLOW_NUMBER = 3

function Chat({ id }) {
  const [messages, setMessages] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { isMuted } = useSound()
  const { play, reset, stop } = useAudioPlayer()
  const { content } = useContent()
  const chatMode = useChatMode();

  const payment = usePaymentInformation();

  useEffect(() => {
    setMessages(content.historyMessages)
  }, [content.historyMessages])

  useEffect(() => {
    if (content.userStartMessage && messages.length === 0) {
      handleSendMessage({
        message: content.userStartMessage,
        type: 'text',
        muteResponse: true
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content.userStartMessage])

  useEffect(() => {
    if (Number(content.currentFlow) === PAYWALL_FLOW_NUMBER) {
      setIsDialogOpen(true)
    }
  }, [content.currentFlow])

  const handleSendMessage = async ({
    message,
    type,
    muteResponse = true
  }) => {
    if (message.length === 0) return
    const outputMessageId = uuidv4()

    setMessages((prev) => [
      { text: message, sender: 'user', id: outputMessageId, type },
      ...prev
    ])

    const requestData = {
      id,
      ...(type === 'text' && { text: message }),
      ...(type === 'image' && { image: message })
    }
    setIsLoading(true)
    const result = await sendMessage({ ...requestData, mode: chatMode, scoreId: payment?.scoreId })

    if (result) {
      setIsLoading(false)
      setMessages((prev) => [
        { text: result.text, sender: 'AI', id: result.id, type: 'text', button: result.button || null },
        ...prev
      ])
      setOptions(Object.values(result.options))


      if (isMuted || muteResponse) return
      const voiceResponse = await getVoiceMessage({ text: result.text, id })

      if (voiceResponse?.audio) {
        play(voiceResponse?.audio)
      }
      if (Number(result.flow) === PAYWALL_FLOW_NUMBER) {
        setIsDialogOpen(true)
      }
    }
    setIsLoading(false)
  }

  return (
    <div className="relative  w-full flex flex-1 flex-col bg-white">
      <MessageList messages={messages} isLoading={isLoading}/>
      {(options.length !== 0 && !isLoading) && (
        <div className="mb-[10px] flex gap-[10px] px-4">
          {options.map((item) => (
            <div
              key={item}
              onClick={() => handleSendMessage({ message: item, type: 'text' })}
              className="cursor-pointer rounded-lg bg-[#fafafa] p-3 text-[12px]	font-semibold text-[#232948] lg:bg-[#ececf1] lg:hover:brightness-[1.02]"
            >
              {item}
            </div>
          ))}
        </div>
      )}
      <div className="px-4 pb-4">
        <InputBar
          onSendMessage={handleSendMessage}
          stopAudio={() => {
            stop()
            reset()
          }}
        />
      </div>
      {/* <PaywallModal
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      /> */}
    </div>
  )
}

export default Chat
