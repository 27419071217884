import { useCallback } from "react";
import { usePaymentInformation } from "../../context/PhotoLoadingContext";
import Modal from "../Modal"
import { SharePaywall } from "../ModalMainPhotoLoading/SharePaywall"
import { getUdid, makeRequest } from "../../utils/request";
import { getPaymentRequest } from "../../utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const InviteModal = ({ opened, onClose }) => {

    const history = useHistory();

    const paymentInformation = usePaymentInformation();

    const onGoToPayment = useCallback(async () => {
        if (!paymentInformation) {
            return;
        }
        const { paymentItem, paymentProject, scoreId } = paymentInformation
        const res = await getPaymentRequest(getUdid(), paymentItem, paymentProject, scoreId)
        if (res.data.redirect_url) {
            window.location.href = res.data.redirect_url;
        } else {
            history.push('/err')
        }
    }, [paymentInformation, history])

    const goToResult = useCallback(() => {
        if(!paymentInformation){
            return;
        }
        makeRequest('/score/advanced/create', 'post', {
            score_id: paymentInformation.scoreId,
        }).then(() => {
            window.dataLayer.push({ 'event': 'go_to_result_page' });
            history.push(`/${paymentInformation.scoreId}`)
        });
    }, [paymentInformation, history])

    return (
        <Modal shouldCloseOnOutline={false} isOpen={opened} onClose={onClose} contentClassName="min-h-[517px] relative overflow-hidden">
            <div className="flex w-full justify-center">
                <div className="w-[241px] flex flex-col pt-[40px] items-center pb-[30px]">
                    <SharePaywall onClickContinue={onGoToPayment} scoreId={paymentInformation?.scoreId} goToResult={goToResult} />
                </div>
            </div>
        </Modal>
    )
}