import { useEffect } from "react";
import { Loader } from "../components/Loader";
import { getPaymentElemId, loadPaymend, loadPayment } from "../utils";
import { makeRequest } from "../utils/request";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOpenInviteModal,  useShowInviteModalPayment } from "../context";

export const ResultCallbackPage = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const showInviteOnPayment = useShowInviteModalPayment();
    const openInviteModal = useOpenInviteModal();
    useEffect(() => {

        const params = new URL(window.location.href)
            .searchParams;
        const checkoudId = params.get("checkout_id");
        getPaymentElemId(checkoudId).then(async ({ resId, email }) => {

            if (email) {
                makeRequest('/user/update', 'post', {
                    tap: 'unlock',
                });

                await makeRequest('/user/update', 'post', {
                    email,
                });
            }
            const res = await loadPayment(resId);
            if (res === 2) {
                if (showInviteOnPayment) {
                    openInviteModal();
                }

                history.push('/');
                return;
            }

            if (resId) {
                makeRequest('/score/advanced/create', 'post', {
                    score_id: resId,
                }).then(() => {
                    window.dataLayer.push({ 'event': 'go_to_result_page' });
                    history.push(`/${resId}`)
                });
            }
        }).catch(() => {
            history.push('/err');
        });
        // get params from url
        // make a request to get status and id
        // if everything is correct - make request and go to result page
    }, [history, showInviteOnPayment, openInviteModal])

    return (
        <>
            <Loader headerText={t('PAYWALL_STATUS')} text={t('LOADING_MESSAGE')} />
        </>
    );
}