import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useOpenChatModal, useShowChatOnPayment } from "../context";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { makeRequest } from "../utils/request";

export const InviteRedirectPage = () => {

    let { photoId } = useParams();
    const history = useHistory();

    useEffect(() => {
        makeRequest('/invite/update', 'post', {
            score_id: photoId
        });

        history.push('/')
    })

    return (
        <>
        </>
    )
}