import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useOpenInviteModal,  useShowInviteModalPayment } from "../context";

export const ErrorCallbackPage = () => {

    const history = useHistory();
    const showInviteOnPayment = useShowInviteModalPayment();
    const openInviteModal = useOpenInviteModal();
    useEffect(() => {
        if (showInviteOnPayment) {
            openInviteModal('payment');
        }

        history.push('/')
    })

    return (
        <>
        </>
    )
}