import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { GoogleOAuthProvider } from "@react-oauth/google"
window.__tgAppRedirectId = window.Telegram?.WebApp?.initDataUnsafe?.start_param || '';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <GoogleOAuthProvider clientId='655228700201-sd1j0bk9j4l0kpc59817cvnoeieurgj4.apps.googleusercontent.com'>
      <App />
    </GoogleOAuthProvider>

);

const fileinput = document.createElement('input');

fileinput.type = 'file';
fileinput.id = "file-loader-input";
fileinput.accept = "image/*";
fileinput.style = "position: absolute; left: -100px; width: 2px; height: 2px; opacity: 0;"
document.body.appendChild(fileinput);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
