import React, { useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { twMerge } from 'tailwind-merge'
import { ClosedIcon } from '../../icons';



const usePreventScrollShake = (isModalOpen) => {
    useEffect(() => {
        if (isModalOpen) {
            const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
            document.body.style.paddingRight = `${scrollbarWidth}px`;
            document.body.style.overflow = 'hidden'; // Prevent scrolling
        } else {
            document.body.style.paddingRight = '';
            document.body.style.overflow = ''; // Restore scrolling
        }

        return () => {
            document.body.style.paddingRight = '';
            document.body.style.overflow = ''; // Clean up when component unmounts
        };
    }, [isModalOpen]);
};

const Modal = ({ isOpen, onClose, shouldCloseOnOutline=true, children, contentClassName, hideCloseButton = false }) => {
    const modalRoot = document.getElementById('modal-root');
    usePreventScrollShake(isOpen)
    // Prevent scrolling when modal is open
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    const clickOutline = useCallback(()=>{
        if(shouldCloseOnOutline){
            onClose();
        }
    }, [onClose, shouldCloseOnOutline])

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div
            className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center  z-50"
            onClick={clickOutline}
        >
            <div
                className={twMerge("bg-white rounded-[32px] bottom-0 shadow-lg w-full max-w-[320px]  relative", contentClassName)}
                onClick={(e) => e.stopPropagation()}
            >
                <>
                    {!hideCloseButton && <button onClick={onClose} className='flex items-center justify-center bg-[rgba(0,0,0,0.04)] rounded-[50%] absolute top-[14px] right-[14px] h-[32px] w-[32px] z-[10]'>
                        <ClosedIcon />
                    </button>}

                    {children}
                </>
            </div>
        </div>,
        modalRoot
    );
};

export default Modal;