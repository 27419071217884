import { useEffect, useRef } from "react"
import { ReactComponent as ScaleSvg } from './scale.svg';
import { ReactComponent as RoundScaleSvg } from './Graph-Color.svg';
import { twMerge } from "tailwind-merge";

export const NewScoreScale = ({ score, className = '' }) => {
    const parentRef = useRef()
    useEffect(() => {
        if (parentRef.current) {
            const elem = parentRef.current;
            const pathes = Array.from(elem.querySelectorAll('path'));
            const pathAmount = Math.ceil((pathes.length * (score / 100)));
            pathes.reverse();
            for (let i = 0; i < pathAmount; i++) {
                if (!pathes[i]) {
                    break;
                }
                pathes[i].style.fill = `url(#paint${pathes.length - i - 1}_linear_714_1922)`;
            }
        }
    }, [score])

    return (
        <ScaleSvg className={twMerge("mt-[44px]", className)} ref={parentRef} />
    )
}

export const RoundScale = ({ score, className = '' }) => {
    const parentRef = useRef()
    useEffect(() => {
        if (parentRef.current) {
            const elem = parentRef.current;
            const pathes = Array.from(elem.querySelectorAll('path'));
            const pathAmount = Math.ceil((pathes.length * (score / 100))) + 1;
            // pathes.reverse();
            for (let i = 0; i < pathAmount; i++) {
                if (!pathes[i]) {
                    break;
                }
                pathes[i].style.fill = `url(#paint${pathes.length - i - 1}_linear_1056_685)`;
            }
        }
    }, [score])

    return (
        <div className="w-[112px] h-[112px] md:w-[145px] md:h-[145px] flex relative items-center justify-center">
            <RoundScaleSvg className={twMerge("absolute top-0 bottom-0 left-0 right-0 w-[112px] h-[112px] md:w-[145px] md:h-[145px]", className)} ref={parentRef} />
            <div className="text-[22px] font-[600] text-[#000]">
                {score}
            </div>
        </div>
    )
    // url(#paint0_linear_1056_685)"
}